import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../../components/layout";
import HeaderTwo from "../../components/header/header-two";
import StickyHeader from "../../components/header/sticky-header";
import Footer from "../../components/footer";
import PageHeader from "../../components/page-header";
import AakdEvent from "../../components/event/aakd";
import AosEvent from "../../components/event/aos";

const About = () => (
  <Layout pageTitle="Made You Love Tennis Foundation | About">
    <HeaderTwo />
    <div className="about-page">
      <StickyHeader extraClassName="stricky-header-two" />
      <PageHeader title="About MYLTF" crumbTitle="About MYLTF" />
      <AosEvent top showbutton />
      <AakdEvent showbutton />
    </div>
    <Footer />
  </Layout>
);

export default About;
